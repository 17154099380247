@import '../../common/variables';
@import '../../common/helpers';
@import '../../common/fonts';

.chapter--famous-guests {
	--background-color-hsl: var(--hsl--chapter--famous-guests);

	.chapter__content {
		padding: 0 0 40vh 0;
		mix-blend-mode: multiply;

		.famous-guests {
			max-width: 70rem;
			margin: 2rem auto 0;
			padding-left: $content-margin; padding-right: $content-margin;

			@media only screen and (min-width: 42rem) {
				display: grid;
				grid-template-columns: repeat(3, minmax(4rem, 33vmin));
				gap: clamp(1rem, 4rem, 4vw);
				margin-bottom: 4rem;
			}
		}

		.guest-no-1 {
			max-width: 70rem;
			padding-left: $content-margin; padding-right: $content-margin;

			.guest {
				margin-bottom: 0;
			}

			.guest__whois {
				text-align: left;

				.headline {
					margin: 2rem 0;
					text-align: center;

					.name {
						display: block;
						margin-top: 1rem;
						@include handwrittenFont(3.5rem);
						line-height: 1;
					}
				}
			}

			@media only screen and (min-width: 42rem) {
				display: grid;
				grid-template-columns: minmax(4rem, 40vmin) 1fr;
				gap: clamp(1rem, 4rem, 4vw);
				margin: 3rem auto 4rem;

				.guest__whois {
					max-width: 28rem;
					margin-top: auto;
				}
			}
		}

		.guest {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			&:last-child:nth-child(3n - 2) {
				grid-column-end: 3;
			}

			.guest__picture {
				display: grid;
				grid-template-columns: 60vw;
				grid-template-rows: 60vw;
				grid-auto-flow: column;
				justify-items: center;
				align-items: end;
				padding: 10%;

				@media only screen and (min-width: 42rem) {
					grid-template-columns: 1fr;
					grid-template-rows: 1fr;
				}

				img {
					grid-row: 1;
					grid-column: 1;
					//clip-path: url(#famousGuestMask);

					// workaround cause of fuckin' Safari...
					object-fit: cover;
					border-radius: 0 0 1000px 1000px;
					transform: scale(1.06, 1.16);
					transform-origin: 50% 100%;
				}

				&:before, &:after {
					grid-row: 1;
					grid-column: 1;
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background: #{'hsla(var(--color-sandy-dark-hsl), 25%)'};
					border-radius: 1000px;
				}

				&:before {
					background: transparent;
					border-radius: 1000px;
					//border: 1px solid #{'hsl(var(--color-sandy-dark-hsl))'};
					border: 1px solid black;
					transform: scale(1.075);
				}
			}

			.guest__whois {
				margin-top: .5rem;

				.name {
					@include headlineFont(1.5rem, bold);
					line-height: 1;
				}
			}
		}
	}
}
