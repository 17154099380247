@import '../common/variables';
@import '../common/fonts';

.site-header {

	.logo-dreckschanke {
		display: block;
		width: clamp(11rem, 28vmin, 40rem);
		height: 0;
		overflow: visible;
		position: fixed;
		top: $content-margin * .7; left: $content-margin * .9;
		z-index: 10000;
		transition: opacity 250ms ease-out;
		pointer-events: none;

		&.hidden {
			opacity: 0;
		}

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 0;
			padding-top: 50%;
			background: transparent url('@/images/logo-dreckschanke-small-typed-transparent.svg') 0 0 no-repeat;
			background-size: contain;
			transition: opacity 250ms ease-out;
		}

		&:after {
			opacity: 0;
			background-image: url('@/images/logo-dreckschanke-small-typed-inverted-thin-border.svg');
			//filter: drop-shadow(0 .5rem .75rem rgba(0, 0, 0, .8));
			filter: drop-shadow(0 0 1.25rem rgba(255, 255, 255, .25));
		}
	}

	.languages {
		position: fixed;
		top: $content-margin;
		right: $content-margin;
		z-index: 10000;
		transition: color 250ms ease-out;

		&__wrapper {
			display: inline-flex;
			gap: .5rem;
		}

		a {
			text-decoration: none;
		}

		.active a {
			@include baseFont(inherit, bold);
			cursor: default;
		}
	}


	&.inverted {

		.logo-dreckschanke {
			&:before { opacity: 0; }
			&:after { opacity: 1; }
		}

		.languages {
			color: white;
		}
	}
}
