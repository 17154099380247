@import 'variables';
@import 'helpers';


@include fontFace('Montserrat', 'Montserrat-Regular');
@include fontFace('Montserrat', 'Montserrat-Black', bold);

@include fontFace('Grenze', 'Grenze-Light');
@include fontFace('Grenze', 'Grenze-Black', bold);

@include fontFace('Dosis', 'Dosis-Light');
@include fontFace('Dosis', 'Dosis-Bold', bold);

@include fontFace('MarckScript', 'MarckScript-Regular');


@mixin baseFont($size: inherit, $weight: normal) {
	font-family: $base-font-family;
	font-weight: $weight;
	font-size: $size;
}

@mixin headlineFont($size: inherit, $weight: normal) {
	font-family: $headline-font-family;
	font-weight: $weight;
	font-size: $size;
}

@mixin additionalFont($size: inherit, $weight: normal) {
	font-family: $additional-font-family;
	font-weight: $weight;
	font-size: $size;
}

@mixin handwrittenFont($size: inherit) {
	font-family: $handwritten-font-family;
	font-weight: normal;
	font-size: $size;
	letter-spacing: -.033em;
}

@mixin flexibleFontSize($min, $preferred, $max, $fallback: null) {
	font-size: #{if($fallback, $fallback, $min)};
	$_: #{'clamp(#{$min}, #{$preferred}, #{$max})'};
	@supports (font-size: #{$_}) {
		font-size: #{$_};
	}
}


.handwritten {
	@include handwrittenFont();
}

.bigger {
	font-size: 1.75em;
}
