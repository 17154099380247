@import '../../common/variables';
@import '../../common/helpers';
@import '../../common/fonts';

.chapter--thanks {
	color: white;
	background: black;

	.chapter__content {

		.sticky-bg-photo::after {
			content: '';
			position: absolute;
			top: 0; left: 0; bottom: 0; right: 0;
			@include scrimGradient(black, to top, (0%, 75%));
			mix-blend-mode: multiply;
		}

		.chapter-headline {
			font-family: $base-font-family;
		}

		article {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			min-width: auto;
			max-width: none;
			margin: 75vh 0 0 0;
			padding: 33vh $content-margin;
			text-align: center;

			p {
				max-width: 32rem;
			}

			@include scrimGradient(black, to top, (50%, 100%));

			.thank-you {
				@include headlineFont(1.75rem, bold);
			}

			.signature {
				@include handwrittenFont(1.5rem);
			}
		}
	}
}
