
//--- offsets -----------------------------------------------------------------

$content-margin: 1.5rem;


//--- fonts -------------------------------------------------------------------

$base-font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
$headline-font-family: Grenze, 'Times New Roman', Times, serif;
$additional-font-family: Dosis, Tahoma, Verdana, Segoe, sans-serif;
$handwritten-font-family: MarckScript, "Brush Script MT", cursive;
$base-font-line-height: 1.5;


//--- effects -----------------------------------------------------------------

$backgroundTransition: background-color 650ms ease-out;


//--- colors ------------------------------------------------------------------

:root {

	// presets
	--dynamic-background-color-hsl: var(--color-sandy-light-hsl);
	--dynamic-text-color-hsl: 0deg, 0%, 0%;

	// theme colors
	--color-sandy-hsl: 34deg, 60%, 59%;
	--color-sandy-light-hsl: 45deg, 100%, 91%;
	--color-sandy-dark-hsl: 30deg, 53%, 53%;
	--color-terracotta-hsl: 2deg, 75%, 40%;
	--color-terracotta-light-hsl: 2deg, 62%, 55%;

	// bg colors of chapters
	--hsl--chapter--intro: var(--color-sandy-light-hsl);
	--hsl--chapter--born-of-legend: var(--color-sandy-light-hsl);
	--hsl--chapter--famous-guests: var(--color-sandy-light-hsl);
	--hsl--chapter--end-of-old-days: var(--color-terracotta-light-hsl);
	--hsl--chapter--light-at-the-end: 0deg, 0%, 0%;
	--hsl--chapter--new-owner: 202deg, 20%, 64%;
	--hsl--chapter--new-beginning: 0deg, 0%, 100%;
	--hsl--chapter--under-contruction: 15deg, 0%, 100%;
}
