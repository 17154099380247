@import '../../common/variables';
@import '../../common/mixins';
@import '../../common/helpers';
@import '../../common/fonts';

.chapter--under-construction {
	--background-color-hsl: var(--hsl--chapter--under-contruction);
	scroll-snap-align: end;

	.chapter-headline {
		font-family: $base-font-family;
	}

	.chapter__content {
		padding-bottom: 0;

		.photo-gallery {
			display: flex;
			flex: none;
			width: 100vw;
			height: 100vh;
			overflow-x: scroll;
			overscroll-behavior-x: contain;
			scroll-behavior: smooth;
			scroll-snap-type: x mandatory;
			-webkit-overflow-scrolling: touch;
			cursor: grab;

			@include noScrollbar;

			&.active {
				scroll-behavior: auto;
				scroll-snap-type: none;
				cursor: grabbing;
			}

			&__item {
				display: flex;
				flex-shrink: 0;
				width: auto;
				min-width: 100vw;
				height: 100vh;
				position: relative;
				scroll-snap-align: center;
				scroll-snap-stop: always;

				picture {
					display: flex;
					width: auto;
					min-width: 100vw;
					height: 100%;
					user-select: none;
					-webkit-user-drag: none;
					pointer-events: none;

					img {
						object-fit: cover;
						width: auto;
						min-width: 100vw;
						height: 100%;
						user-select: none;
						-webkit-user-drag: none;
						pointer-events: none;
					}
				}

				.photo-legend {
					position: absolute;
					inset: 0 0 0 0;
					pointer-events: none;
					box-shadow: inset -1px 0 1px rgba(255, 255, 255, .5);
					user-select: none;

					&__content {
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						box-sizing: border-box;
						width: 100vw;
						height: 50vh;
						position: sticky;
						top: 50vh;
						left: 0;
						z-index: 10;
						padding: 0 $content-margin ($content-margin * .75) $content-margin;
						font-size: .85rem;
						color: white;
						@include scrimGradient(black, 35deg, (0%, 50%));

						* {
							max-width: #{'max(20rem, 75vw)'};
						}

						:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
