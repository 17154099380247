@import '../common/fonts';

.photo {
	&.rotate-n3 img { transform: rotate(-3deg); }
	&.rotate-n2 img { transform: rotate(-2deg); }
	&.rotate-n1 img { transform: rotate(-1deg); }
	&.rotate-1 img { transform: rotate(1deg); }
	&.rotate-2 img { transform: rotate(2deg); }
	&.rotate-3 img { transform: rotate(3deg); }

	img {
		max-height: 33vmin;
	}

	&.bordered img {
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: .25rem;
	}

	&.wide img {
		max-height: 75vh;
		width: 100%;
	}

	.comment {
		margin: .75rem 0 0 .25rem;
		opacity: .7;

		&, & small {
			@include headlineFont(1.25rem, normal);
			line-height: 1.2;
		}
	}
}
