@import '../common/variables';
@import '../common/helpers';
@import '../common/mixins';

.photo-slider {
	display: flex;
	flex-wrap: nowrap;
	position: relative;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	will-change: transform;

	padding: 2vw #{'max(1.5rem, calc((100vw - 67rem) / 2))'};
	gap: 2vw;

	scroll-behavior: smooth;
	cursor: grab;

	@include noScrollbar;

	&.active {
		cursor: grabbing;
	}

	.photo {
		flex: 0 0 auto;
		max-width: 33vmax;
		user-select: none;

		img {
			-webkit-user-drag: none;
			pointer-events: none;
		}
	}
}
