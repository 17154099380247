@import '../../common/variables';
@import '../../common/fonts';

.chapter {
	width: 100%;
	min-height: 100vh;
	background-color: #{'hsl(var(--background-color-hsl))'};
	transition: $backgroundTransition;
}

.chapter__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	overflow: hidden;
	padding: 20vh 0;

	article {
		margin: 2rem auto 4rem;
		padding-left: $content-margin; padding-right: $content-margin;

		.text {
			position: relative;
		}

		aside {
			.photo {
				margin-bottom: 3rem;
				text-align: center;
			}
		}

		@media only screen and (min-width: 42rem) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 4rem;
			width: 100%;
			max-width: 70rem;
			min-width: 66vw;

			.text {
				grid-column: 1;
				max-width: 32rem;
				margin-right: auto;

				&.wide {
					grid-column: 1 / span 2;
					max-width: 40rem;
				}
			}

			aside {
				grid-column: 2;

				&.on-left {
					grid-column: 1;
				}

				.photo {
					gap: 2rem;
					text-align: left;

					&.wide img {
						width: auto;
					}

					@media only screen and (min-width: 60rem) {
						&:not(.wide) {
							display: flex;

							.comment {
								margin: auto 0 0 0;
							}
						}
					}
				}
			}
		}
	}
}

.chapter-headline {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-bottom: 4rem;
	text-align: center;
}

.milestone-year {
	display: inline-flex;
	align-items: flex-start;
	@include additionalFont(1.75rem, bold);
	line-height: 1;

	strong {
		display: block;
		margin: 0 calc(-1.1rem - 4px);
		padding: .25rem 1.25rem;
		border: 2px solid black;
		z-index: 2;
		background-color: white;
	}

	&:before, &:after {
		content: '';
		display: block;
		width: 3.5rem; height: calc(2.85rem + 4px);
	}

	&:before {
		background: transparent url('../images/milestone-year-shape-left.svg') 100% 0 no-repeat;
		background-size: contain;
	}

	&:after {
		background: transparent url('../images/milestone-year-shape-right.svg') 0 0 no-repeat;
		background-size: contain;
	}
}

.chapter__content--w-sticky-bg-photo {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	overflow: visible;
	padding-top: 0; padding-bottom: 0;

	article {
		z-index: 0;
		grid-row: 1;
		grid-column: 1;
	}

	.sticky-bg-photo {
		grid-row: 1;
		grid-column: 1;
		width: 100%;
		height: 100vh;
		position: sticky;
		top: 0;
		overflow: hidden;

		picture {
			display: flex;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}
}
