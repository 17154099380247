
@mixin fontFace($family, $file, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		font-weight: $weight;
		font-style: $style;
		src: url('../fonts/#{$file}.eot'); // IE9 Compat Modes
		src: url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype'), // IE6-IE8
		url('../fonts/#{$file}.woff2') format('woff2'), // Super Modern Browsers
		url('../fonts/#{$file}.woff') format('woff'), // Pretty Modern Browsers
		url('../fonts/#{$file}.ttf') format('truetype'); // Safari, Android, iOS
	}
}

@mixin scrimGradient($color: black, $direction: 'to bottom', $limits: (0%, 100%), $invertedAlpha: false, $property: background) {
	$scrimCoordinates: (
		0: 1,
		19: 0.738,
		34: 0.541,
		47: 0.382,
		56.5: 0.278,
		65: 0.194,
		73: 0.126,
		80.2: 0.075,
		86.1: 0.042,
		91: 0.021,
		95.2: 0.008,
		98.2: 0.002,
		100: 0
	);

	$stops: ();
	$stopRange: nth($limits, 2) - nth($limits, 1);

	@each $colorStop, $alphaValue in $scrimCoordinates {
		$offset: nth($limits, 1) + $colorStop/100 * $stopRange;
		$alpha: $alphaValue;
		@if $invertedAlpha == true {
			$alpha: 1 - $alphaValue;
		}
		$stop: null;
		@if type-of($color) == 'color' {
			$stop: change-color($color, $alpha: $alpha) $offset;
		} @else {
			$stop: unquote('hsla(#{$color}, #{$alpha}) #{$offset}');
		}

		$stops: append($stops, $stop, comma);
	}

	#{$property}: linear-gradient($direction, $stops);
}
